<template>
    <div class="index-box">
        <div class="bg-cont">
            <div class="font-img">
                <img src="../assets/images/bg-font.png" />
            </div>
            <div class="email">
                <div class="ema-title">
                    <img src="../assets/images/email.png" />
                    <span>联系邮箱</span>
                </div>
                <div class="ema-font" id="foo1">{{ textToCopy }}</div>
                <a href="javascript:;" id="btn" data-clipboard-action="copy" data-clipboard-target="#foo1"
                    @click="copyFun">联系我们</a>

            </div>
            <div class="line"></div>
            <div class="ewm">
                <img src="../assets/images/ewm.png" />
                <p>官方客服咨询</p>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
export default {

    data() {
        return {
            textToCopy: 'support001@huiyi-service.com'
        };
    },
    created() {
    },
    methods: {
        copyText() {
            let that = this;
            const clipboard = new Clipboard('#btn', {
                text: () => that.textToCopy
            });
            console.log("clipboard", clipboard)
            clipboard.on('success', () => {
                console.log("w344");
                // that.$message.success("复制成功");
            });
            clipboard.on('error', () => {
                console.log('复制失败');
                // 可以添加复制失败后的处理逻辑
            });
            clipboard.onClick = () => {
                setTimeout(() => {
                    clipboard.destroy();
                }, 1500);
            };


        },

        copyFun() {
            let clipboard = new Clipboard('#btn'); // 获取点击按钮的元素
            /* 注意此事件监听是异步的   */
            clipboard.on('success', (e) => {
                console.info('Text:', e.text);
                e.clearSelection();
                // 释放内存
                clipboard.destroy();
                return this.$message.success("复制成功");
            });
            // 复制失败
            clipboard.on('error', (e) => {
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
                // 释放内存
                clipboard.destroy();
                alert('该浏览器不支持自动复制');
            });
        },

    }
};
</script>

<style scoped>
.index-box {
    background-image: url("../assets/images/contact-bg.png");
    background-color: #5b8cad;
    position: relative;
    height: 80rem;
    background-repeat: no-repeat;
    margin-top: 10.5rem;
    position: relative;
    background-size: cover;
    /* 背景图片覆盖整个容器 */
    background-position: center;
    position: relative;
}

.bg-cont {
    width: 49rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.font-img img {
    width: 49rem;
}

.email {
    width: 70rem;
    background: url('../assets/images/mencheng.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top:3rem;
    padding-bottom:4rem;

}

.ema-title {
    display: flex;
    align-items: center;
}

.ema-title img {
    width: 2.4rem;
    height: 1.9rem;
}

.ema-title span {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-left: 1rem;
}

.ema-font {
    padding:1.2rem 1.4rem;
    background: url('../assets/images/font-tip.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 2.3rem;
    font-weight: 400;
    font-size: 2.3rem;
    color: #000000;
}

.line {
    width: 20.8rem;
    height: 2.2rem;
    background: url('../assets/images/line.png') no-repeat;
    background-size: cover;
    margin-top: 4rem;
}

.email a {
    width: 19rem;
    padding:1.5rem 0px;
    display: block;
    background: #0486FF;
    border-radius: 8px;
    font-weight: 600;
    font-size: 2rem;
    color: #FFFFFF;
    margin-top: 2rem;
    text-align: center;
}

.ewm {
    width: 18.2rem;
    height: 22.4rem;
    background: url('../assets/images/ewm-bg.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
}

.ewm img {
    width: 15.8rem;
    height: 15.8rem;
    display: block;
    margin-top: 1rem;
}

.ewm p {
    font-weight: 400;
    font-size: 20px;
    color: #888888;
    line-height: 5.4rem;
}
</style>